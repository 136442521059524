
import { defineComponent } from 'vue';
import maskUtil from '@/utils/mask';
import { capitalize, numberFormat } from '@/utils/text';
import { Getters } from '@/store/enums/UserEnums';
import { mapGetters } from 'vuex';
import Avatar from '@/views/new-design/pages/ViewTenantReview/Avatar.vue';

export default defineComponent({
  props: {
    info: {
      default: () => {
        return {};
      },
    },
    averageRating: {
      type: String,
      default: '0.00',
    },
  },
  components: {
    Avatar,
  },
  computed: {
    ...mapGetters({
      avatarSrc: Getters.GET_AVATAR,
    }),
    rate() {
      return numberFormat(this.averageRating, 1);
    },
    mobileMasking() {
      return maskUtil.mobile.aus;
    },

    fullName() {
      const fname = capitalize((this.info as any)?.first_name || '');
      const lname = capitalize((this.info as any)?.last_name || '');

      return `${fname} ${lname}`;
    },
    tenantRole() {
      return 'Tenant';
    },
  },
});
