
import { defineComponent } from 'vue';
import { numberFormat } from '@/utils/text';
import timeUtil from '@/utils/time';
import GoogleMap from '@/views/new-design/components/GoogleMap.vue';
import { Getters as BusinessGetters } from '@/store/enums/BusinessEnums';
import BusinessLogo from '@/components/logo/BusinessLogo.vue';
import { mapGetters } from 'vuex';
import text from '@/utils/text';
import Avatar from '@/views/new-design/pages/ViewTenantReview/Avatar.vue';
import { Getters } from '@/store/enums/TenantReviewEnums';

const badgeColor = {
  yes: 'badge-yes',
  no: 'badge-no',
  'n/a': 'badge-not-applicable',
};
export default defineComponent({
  props: {
    info: {
      default: () => {
        return {} as any;
      },
    },
  },
  watch: {
    info: {
      handler() {
        this.$nextTick(() => {
          (this.$refs.map as any).searchMap();
        });
      },
      deep: true,
    },
  },
  mounted() {
    this.loadMap();
  },
  components: {
    GoogleMap,
    BusinessLogo,
    Avatar,
  },
  computed: {
    ...mapGetters({
      getBusinessLogo: BusinessGetters.GET_AGENT_BUSINESS_LOGO,
      agentAvatar: Getters.GET_AGENT_AVATAR,
    }),
    hasEmail() {
      return (this.info as any)?.user?.email;
    },
    hasMobileNumber() {
      return (this.info as any)?.user?.mobile_number;
    },
    address() {
      return (this.info as any)?.user?.roles[0].role == 'Admin'
        ? null
        : `${(this.info as any)?.user?.business_address_line}, ${
            (this.info as any)?.user?.business_city
          }, ${(this.info as any)?.user?.business_state}, ${
            (this.info as any)?.user?.business_post_code
          }`;
    },
    tenantAddress() {
      return `${(this.info as any)?.address_line}, ${
        (this.info as any)?.city
      }, ${(this.info as any)?.post_code}, ${(this.info as any)?.state}`;
    },
    hasLogo() {
      return (
        (this.info as any)?.user?.business_logo &&
        (this.info as any)?.user?.business_logo.trim() != ''
      );
    },
    headerColor() {
      if (!(this.info as any)?.user?.business_color) {
        return '#4070ea';
      }

      return (this.info as any)?.user?.business_color ?? '#4070ea';
    },
    formattedAbn() {
      return this.info?.user?.abn ? text.formatABN(this.info?.user?.abn) : null;
    },
    formattedAcn() {
      return this.info?.user?.acn ? text.formatACN(this.info?.user?.acn) : null;
    },
  },
  methods: {
    getColor(answer = '' as string) {
      return badgeColor[answer.toLowerCase()];
    },
    dateFormat(date) {
      return timeUtil.dateFormat(date);
    },
    numberWithCommas(x: any) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    formatAverageRating(rating) {
      return numberFormat(rating);
    },
    async loadMap() {
      await (this.$refs.map as any).initializeMap();
      this.$nextTick(() => {
        (this.$refs.map as any).searchMap();
      });
    },
  },
});
