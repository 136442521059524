
import { defineComponent } from 'vue';
import Header from '@/views/new-design/pages/ViewTenantReview/Report/Header.vue';
import Title from '@/views/new-design/pages/ViewTenantReview/Report/Title.vue';
import TenantHeader from '@/views/new-design/pages/ViewTenantReview/Report/TenantHeader.vue';
import ReportContent from '@/views/new-design/pages/ViewTenantReview/Report/ReportContent.vue';
import Footer from '@/views/new-design/pages/ViewTenantReview/Report/Footer.vue';

export default defineComponent({
  props: {
    fileName: { default: 'Report' },
    orientation: { default: 'portrait' },
    info: {
      default: () => {
        return {};
      },
    },
    averageRating: {
      type: String,
      default: '0.00',
    },
  },
  components: {
    Header,
    Title,
    TenantHeader,
    ReportContent,
    Footer,
  },
  methods: {
    generatePDf() {
      (this.$refs.html2Pdf as any).generatePdf();
    },
  },
});
