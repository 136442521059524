
import { defineComponent } from 'vue';
const __default__ = defineComponent({
  name: 'tenant-avatar',
  props: {
    src: {
      type: String,
    },
    fill: {
      type: String,
      default: 'none',
    },
    height: { default: '74px' },
    width: { default: '74px' },
  },
  computed: {
    avatarSrc() {
      return this.src && this.src != ''
        ? this.src
        : require('@/assets/new-design/images/default-user.png');
    },
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "acdb060c": (_ctx.height),
  "485dfb93": (_ctx.width)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__