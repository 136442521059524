
import { ref, onBeforeMount, onMounted } from 'vue';
import toasts from '@/utils/toasts';

const __default__ = {
  name: 'GMap',
  props: {
    center: { lat: Number, lng: Number },
    zoom: Number,
    mapType: String,
    disableUI: Boolean,
    draggable: { default: true },
    query: { default: null },
    height: { default: '300px' },
    coordinates: {
      default: () => {
        return { lat: 38.0, lng: -77.01 };
      },
    },
  },
  setup(props) {
    const mapDivRef = ref(null);
    let map = ref(null);
    let markers = [] as any;

    const initializeMap = async () => {
      map = new (window as any).google.maps.Map(mapDivRef.value, {
        mapTypeId: (props as any).mapType || 'hybrid',
        zoom: (props as any).zoom || 8,
        disableDefaultUI: (props as any).disableUI || false,
        center: (props as any).center || { lat: 38.0, lng: -77.01 },
        draggable: (props as any).draggable,
      });
    };

    const search = async (callback) => {
      const request = {
        query: (props as any).query,
        fields: ['name', 'geometry'],
      };

      const service = new (window as any).google.maps.places.PlacesService(map);

      service.findPlaceFromQuery(request, (results, status) => {
        if (
          status ===
            (window as any).google.maps.places.PlacesServiceStatus.OK &&
          results
        ) {
          callback(results[0].geometry!.location!);
        } else {
          callback(false);
        }
      });
    };

    const markMap = (result) => {
      const marker = new (window as any).google.maps.Marker({
        map,
        position: result,
      });
      markers.push(marker);
    };

    const setMapOnAll = (map) => {
      for (let i = 0; i < markers.length; i++) {
        markers[i].setMap(map);
      }
    };

    const deleteMarkers = () => {
      setMapOnAll(null);
      markers = [];
    };

    const searchMap = async () => {
      deleteMarkers();

      // if ((props as any).query) {
      //   search((result) => {
      //     if (result) {
      //       markMap(result);
      //       (map as any).setCenter(result);
      //     } else {
      //       // toasts.error({}, 'Google Map Location not found.');
      //     }
      //   });
      // }
      const coord = {
        lat: parseFloat((props as any).coordinates.lat),
        lng: parseFloat((props as any).coordinates.lng),
      };

      markMap(coord);
      (map as any).setCenter(coord);
    };

    (window as any).initMap = async () => {
      await initializeMap();
      await searchMap();
    };

    return {
      mapDivRef,
      initializeMap,
      searchMap,
      search,
      markMap,
    };
  },
};

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "fa06d2ba": (_ctx.height)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__